import React, { useState, useEffect } from 'react';
import PersonaCard from './PersonaCard';
import API from '../api'; // Adjust the import path as needed

function PersonasPage({ onSelectPersonas }) {
  const [personas, setPersonas] = useState([]);

  useEffect(() => {
    const fetchPersonas = () => {
      API.get('/api/personas/')
        .then((response) => setPersonas(response.data))
        .catch((error) => {
          console.error(error);
        });
    };
    fetchPersonas();
  }, []);

  return (
    <div>
      <h2>My Personas</h2>
      <div className="persona-list">
        {personas.map((persona) => (
          <PersonaCard
            key={persona.id}
            persona={persona}
            onSelect={onSelectPersonas}
          />
        ))}
      </div>
    </div>
  );
}

export default PersonasPage;
