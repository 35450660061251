import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ username, onLogout }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false); // State to manage dropdown visibility
  const [selectedItem, setSelectedItem] = useState(null);

  const handleNavigation = (path, item) => {
    setSelectedItem(item);
    navigate(path);
  };

  const toggleMenu = () => {
    setShowMenu((prev) => !prev); // Toggle the dropdown menu
  };

  return (
    <div className="sidebar">
      <ul>
        <li 
          className={selectedItem === 'home' ? 'selected' : ''} 
          onClick={() => handleNavigation('/', 'home')}
        >
          <i className="fa fa-home"></i> Home
        </li>
        <li 
          className={selectedItem === 'personas' ? 'selected' : ''} 
          onClick={() => handleNavigation('/personas', 'personas')}
        >
          <i className="fa fa-id-badge"></i> Personas
        </li>
        <li 
          className={selectedItem === 'generate' ? 'selected' : ''} 
          onClick={() => handleNavigation('/generate-persona', 'generate-persona')}
        >
          <i className="fa fa-user-plus"></i> New Persona
        </li>
      </ul>
      <div className="account-container">
        <button onClick={toggleMenu} className="account-button">
          {username} <i className="fa fa-user"></i>
        </button>
        {showMenu && (
          <div className="account-menu">
            <button onClick={onLogout}>Logout</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;